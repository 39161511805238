import React from 'react';
import styled from 'styled-components';

const Header = () => {
  return (
    <Wrapper>
      <Title>To Do</Title>
    </Wrapper>
  );
};

const Title = styled.h1`
  color: #000;
  font-size: 4rem;
  font-weight: bold;
`;

const Wrapper = styled.header`
  margin-bottom: 2rem;
`;


export default Header;
import React from 'react';
import styled from 'styled-components';
import sampleData from "./sample-data.json";

import Header from './Header';
import Layout from './Layout'
import TodoList from './TodoList'
import TodoForm from './TodoForm'
import { QUERIES } from './constants';

function App() {
  // get todo's from local storage; return a function to run lazy initializers on mount
  const [ toDoList, setToDoList ] = React.useState(
    () => JSON.parse(window.localStorage.getItem('toDoList')) || sampleData
  );

  const handleToggle = (id) => {
    let updatedTodoItems = toDoList.map(task => {
      return task.id === Number(id) ? { ...task, complete: !task.complete } : { ...task };
    });
    
    setToDoList(updatedTodoItems);
  }

  const addTask = (userInput) => {
    let copiedList = [...toDoList];
    copiedList = [...copiedList, { id: toDoList.length + 1, task: userInput, complete: false }];
    setToDoList(copiedList);
  }

  const handleRemoveItem = (event) => {
    const id = event.currentTarget.getAttribute("id")
    let copiedList = [...toDoList.filter(task => task.id !== Number(id))];
    setToDoList(copiedList);
  };

  // store todo's in local storage
  React.useEffect(() => {
    window.localStorage.setItem('toDoList', JSON.stringify(toDoList))
  }, [toDoList])

 return (
   <div className="App">
    <Layout>
      <TodoListWrapper>
        <TodoList 
          toDoList={toDoList} 
          handleToggle={handleToggle} 
          handleRemoveItem={handleRemoveItem}
        />
        <TodoForm addTask={addTask}/>
      </TodoListWrapper>
    </Layout>

   </div>
 );
}

const TodoListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default App;
// Mobile first
export const BREAKPOINTS = {
  phoneMin: 450,
  tabletMin: 550,
  laptopMin: 1100,
  desktopMin: 1500,
  ultraWideMin: 1918
};

// mobile first
export const QUERIES = {
  phoneMinAndUp: `(min-width: ${BREAKPOINTS.phoneMin / 16}rem})`,
  tabletAndUp: `(min-width: ${BREAKPOINTS.tabletMin / 16}rem)`,
  laptopAndUp: `(min-width: ${BREAKPOINTS.laptopMin / 16}rem)`,
  desktopAndUp: `(min-width: ${BREAKPOINTS.desktopMin / 16}rem)`,
  ultraWideAndUp: `(min-width: ${BREAKPOINTS.ultraWideAndUp / 16}rem)`,
  tabletOnly: `
    (min-width: ${BREAKPOINTS.tabletMin / 16}rem) and
    (max-width: ${(BREAKPOINTS.laptopMin - 1) / 16}rem)`,
};

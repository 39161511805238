import React from 'react';
import styled from 'styled-components';


export default function Checkbox({todo, handleTodoUpdate, checked}) {
  return (
    <div>
      <CheckboxInput
        type="checkbox"
        name="todo-checkbox" 
        id={todo.id}
        checked={checked}
        onChange={handleTodoUpdate}
      />
    </div>
  )
}

const CheckboxInput = styled.input`
  margin-top: 2px;
  flex: 1;
  margin-right: 2rem;
  height: 20px;
  width: 20px;
  min-width: 20px;
`;
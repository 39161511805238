import React from 'react';
import styled from 'styled-components'
import TodoComponent from '../TodoComponent'
import UnstyledButton from '../UnstyledButton'
import Icon from '../Icon'
import Spacer from '../Spacer'

const TodoList = ({toDoList, handleToggle, handleRemoveItem}) => {

  return (
    <List>
      {toDoList.map((item) => {
        return (
          <TodoLayout key={item.id}>
            <TodoComponent
              todo={item} 
              handleToggle={handleToggle}
              />
            <Spacer axis="horizontal" size={40} />
            <RemoveButton
              onClick={handleRemoveItem}
              id={item.id}>
              <TrashIcon
                id="trashOutline"
                strokeWidth={2}
                size={16}
                width={16} />
            </RemoveButton>

          </TodoLayout>
        )
      })}
    </List>
  )
}
const List = styled.ul`
`;

const TodoLayout = styled.div`
  display: flex;
  margin-top: .4rem;
`;

const RemoveButton = styled(UnstyledButton)`
  grid-area: remove;
  display: inline-block;
  top: 10px;
  right: 0;
  padding: 4px;
  margin-left: auto;
`;

const TrashIcon = styled(Icon)`
  color: rgba(248, 113, 113, .7);
  &:hover {
    color: rgba(248, 113, 113, 1);
  }
`;

export default TodoList
import React from 'react'
import styled from 'styled-components';

const Todo = ({todo, checked, handleTodoUpdate}) => {
  return (
    <>
      <ListItem
        id={todo.id}
        onClick={handleTodoUpdate}
        className={`${todo.complete ? "completed" : ""}`}
        >
        <span>{todo.task}</span>
      </ListItem>
    </>
  )
}

const ListItem = styled.li`
  cursor: pointer;
  padding: 4px;
  user-select: none;
`;

export default Todo
import React from 'react';

import Checkbox from '../Checkbox';
import Todo from '../Todo'

// lift state, share btwn checkbox and list item
export default function TodoComponent({todo, handleToggle}) {
  const [checked, setChecked] = React.useState(todo.complete ? true : false);
 
  function toggle(value) {
    return !value
  }

  const handleTodoUpdate = (e) => {
    // e.preventDefault()
    handleToggle(e.currentTarget.id)
    setChecked(toggle)
  }
  
  return (
    <>
      <Checkbox
        type="checkbox"
        name="todo-checkbox" 
        id="todo-checkbox"
        todo={todo}
        checked={checked}
        handleTodoUpdate={handleTodoUpdate}
      />
      <Todo
        todo={todo}
        handleTodoUpdate={handleTodoUpdate}   
      />
    </>
  )
}
import React from 'react';
import styled from 'styled-components';

export default function TodoForm({addTask}) {
  const [ userInput, setUserInput ] = React.useState('');

  const handleChange = (e) => {
    setUserInput(e.currentTarget.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    addTask(userInput);
    setUserInput('');
  }

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit}>  
        <Label htmlFor="todo list">
          Add a todo item to the list.
        </Label>
        <TextAreaWrapper>
          <TextArea
            id="todoItem"
            name="todoItem"
            rows={3}
            placeholder="Your todo item"
            value={userInput} 
            type="text" 
            onChange={handleChange} 
          />
        </TextAreaWrapper>
        {/* <HelperText>Add your todo items here. </HelperText> */}
      
        <ButtonWrapper>
          <Button
            type="submit"
          >
            Submit
          </Button>
       </ButtonWrapper>
    </Form>
  </Wrapper>
  )
}

const Form = styled.form`
`;

const Wrapper = styled.div`
  margin-top: 4rem;
`;

const Label = styled.label`
  display: block;
  font-weight: 500;
  color: rgba(55, 65, 81, 1);
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const TextAreaWrapper = styled.div`
  margin-top: 0.25rem;
`;

// focus:ring-indigo-500 focus:border-indigo-500 
const TextArea = styled.textarea`
  display: block;
  box-shadow: 0 0 2rem -1rem rgba(0,0,0,0.2);
  width: 100%;
  border-radius: 0.375rem;
  color: rgba(107, 114, 128, 1);
  border-color: rgba(209, 213, 219, 1);
`;

const HelperText = styled.p`
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgba(107, 114, 128, 1);
`;

const ButtonWrapper = styled.div`
  margin-top: 2rem;
`;

const Button = styled.button`
  display: inline-flex;
  justify-content: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-width: 1px;
  border-color: transparent;
  box-shadow: 0 0 2rem -1rem rgba(0,0,0,0.2);
  font-weight: 700;
  border-radius: 0.25rem;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(37, 99, 235, 1);
  width: 100%;
  &:hover{
    background-color: rgba(30, 64, 175, 1);
    cursor: pointer;
  }
`;
/* eslint-disable no-unused-vars */
import React from "react"
import styled from "styled-components"

import Header from "../Header"
import Footer from "../Footer"
import { QUERIES } from "../constants"

const Layout = ({ children}) => {

  return (
    <>
      <Wrapper>
       <Header />
        <Section>
          {children}
        </Section>
       <Footer />
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
  header, main, footer {
    flex-shrink: 0;
  }
  @media ${QUERIES.tabletAndUp} {
    margin-right: 0;
    margin-left: 0;
    height: 100%; 
  } 
`;

const Section = styled.section`
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
`;


export default Layout
